@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.bg
{
  border: #0D0120 20px solid;
}


.hello-world {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 24px;
  color: white;
  background-color: #0D0120;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .hello-world {
    top: 0;
    left: 0;
  }
  
}

@media screen and (max-width: 800px) {
  .avatar
  {
    width: 28px;
    height: 28px;
  }
}

.hello-world-right
{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: #FFFFFF;
}


@font-face {
  font-family: ka1;
  src: url('../public/ka1.ttf');
}

.ka1
{
  font-family: ka1;
}

@font-face {
  font-family: Glory;
  src: url('../public/Glorifydemo-BW3J3.otf');
}

.Glory
{
  font-family: Glory;
  font-size: 30px;
}

@font-face {
  font-family: Ostrich;
  src: url('../public/OstrichSans-Medium.otf');
}

.Ostrich
{
  font-family: Ostrich;
  font-size: 44px;
  color: #FFB870;
}

@font-face {
  font-family: Satoshi;
  src: url('../public/Satoshi-Bold.otf');
}

.Satoshi
{
  font-family: Satoshi;
}

@font-face {
  font-family: Glacial;
  src: url('../public/GlacialIndifference-Regular.otf');
}

.Glacial
{
  font-family: Glacial;
}

@font-face {
  font-family: Grias;
  src: url('../public/Griastetrial.otf');
}

.Grias
{
  font-family: Grias;
}

@font-face {
  font-family: KG;
  src: url('../public/KGHAPPY.ttf');
}

.KG
{
  font-family: KG;
}


.button-86 {  
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.grainy-background {
  position: relative;
  overflow: hidden;
}

.grainy-background {
  /* Add grainy background */
  background-image: url('../src/images/grain.png');
  /* Add linear gradient background using CSS variable */
  background-image: 
    url('../src/images/grain.png');
  /* Other styles */
  /* Ensure background size covers the entire container */
  background-size: cover;
}


.randombutton
{
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.glow
{
  stroke: white;
}

.randombutton {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.randombutton:hover {
  transform: translateY(-3px);
}
